module.exports = {
	user: "User",
	userList: "User List",
	payment: "Payment",
	ViewOtp: "OTP List",
	verCode: "User Code",

	billing: "Billing",

	transaction: "Transaction",
	entry: "Entry",
	history: "History",

	byItem: "By Item",
	balance: "Balance",
	balanceMutation: "Balance Mutation",

	bill: "Bill",
	due: "Due",

	settings: "Settings",
	changePassword: "Change Password",

	login: "Login",
	logout: "Logout",

	name: "Name",
	phone: "Phone",
	userType: "User Type",

	actions: "Actions",
	search: "Search",
	copy: "Copy",

	oldPassword: "Old Password",
	newPassword: "New Password",
	newPasswordConfirmation: "New Password Confirmation",
	type: "Type",
	save: "Save",

	"No Bill Available": "No Bill Available",
	dueAmount: "Due Amount",
	dueDate: "Due Date",
	uniqueCode: "Unique Code",
	bankAccount: "Bank Account",
	remainingAmount: "Remaining Amount",
	splitAmount: "Split Amount",

	Price: "Price",
	Revenue: "Revenue",
	Remark: "Remark",
	UserType: "UserType",
	Amount: "Amount",
	Date: "Date",
	Name: "Name",

	price: "Price",
	revenue: "Revenue",
	buyingPrice: "Buying Price",
	sellingPrice: "Selling Price",
	category: "Category",
	mutation: "Mutation",

	back: "Back",
	ceiling: "Ceiling",
	viewBy: "View By",
	view: "View",
	date: "Date",
	month: "Month",
	year: "Year",
	fromToDate: "From-To (Date)",
	fromToDatetime: "From-To (Date & Time)",
	from: "From",
	to: "To",

	January: "January",
	February: "February",
	March: "March",
	May: "May",
	June: "June",
	July: "July",
	August: "August",
	October: "October",
	December: "December",
	remark: "Remark",
	transactionLog: "Transaction Log",
	transactionPartner: "Transaction Partner",
	Partner: "Partner",

	cancel: "Cancel",
	billHistory: "Bill History",
	paymentHistory: "Payment history",
	paidAmount: "Paid Amount",

	Ready: "Ready",
	"Payment Request": "Payment Request",
	"Partially Paid": "Partially Paid",
	Paid: "Paid",
	Done: "Done",
	Outstanding: "Oudstanding",
	Splitted: "Splitted",
	billDetail: "Bill Detail",
	splitBill: "Split Bill",
	deduct: "Deduct",
	add: "Add",

	accountName: "Account Name",
	accoutNo: "Account Number",
	bankName: "Bank Name",
	show: "Show",

	"Are you sure want to": "Are you sure want to",
	delete: "Delete",
	edit: "Edit",

	"Total Bill": "Total Bill",
	"Total Amoun": "Total Amount",
	"Balance Mutation": "Balance Mutation",
	"IN/OUT": "IN/OUT",
	"FROM/TO": "FROM/TO",
	in: "IN",
	out: "OUT",
	"Rows per page": "Rows per page",
	of: "of",
	All: "All",
	"Daily Transaction": "Daily Transaction",
	Others: "Others",
	close: "Close",
	UnqAmount: "UnqAmount",
	Confirm: "Confirm",
	forgetPass: "Forget Password ?",
	enterEmailOrPass: "Enter Email or Phone Number",
	emailOrPhone: "Email/Phone Number",
	feeTemplate: "Fee Template",
	"Fee Templates": "Fee Templates",
	"Template Name": "Template Name",
	"No available templates": "No available templates",
	deposit: "Deposit User",
	dispensation: "Dispensation",
	Remaining: "Remaining",
}
