import React from "react"
import { withStyles } from "@material-ui/core/styles"

import { withTranslation } from "react-i18next"
import { compose } from "redux"

import Table from "components/Table/Table"
import GSearchActions from "components/GSearchActions"
import axios from "axios"
import Config from "config"
import { fnumber } from "components/Helper"

import { Dropdown, Statistic, Dimmer, Loader, Segment, Label, Button, Checkbox, Confirm, Tab } from "semantic-ui-react"
import CButton from "components/CustomButtons/Button"

import PropTypes from "prop-types"
import classNames from "classnames"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Pagination from "components/Pagination/Pagination.js"
import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Badge from "components/Badge/Badge.js"
import Input from "components/CustomInput/CustomInput.js"

import moment from "moment"
import secureStorage from "libs/secureStorage"
import _ from "lodash"

import { SalesType, DirectUserType, EndUsers } from "content/User/libs"
import { CircularProgress, ClickAwayListener, Tooltip } from "@material-ui/core"

// Modal Components
import Zoom from "@material-ui/core/Zoom"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import { Close, FileCopy } from "@material-ui/icons"
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js"
import ReactJson from "react-json-view"
import modalReceipt from "helpers/modalReceipt"

const styles = (theme) => ({
	...modalStyle(theme),
	...style,
})

const CustomTransition = React.forwardRef(function Transition(props, ref) {
	return <Zoom ref={ref} {...props} />
})

class TransactionLog extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			rows: [],
			extractedRows: [],

			total: [],

			selectedEndUsers: [],
			endUser: [],

			selectedCas: [],
			ca: [],

			selectedSubcas: [],
			subca: [],

			selectedSalesDms: [],
			salesDm: [],

			selectedMitraDms: [],
			mitraDm: [],

			selectedMitraTls: [],
			mitraTl: [],

			selectedProducts: [],
			products: [],

			selectedPartners: [],
			partners: [],

			selectedCategories: [],
			categories: [],

			salesType: 0,

			user: secureStorage.getItem("user"),

			rowsPerPage: 15,
			currentPage: 1,
			totalPages: 0,
			totalData: 0,
			currentPageData: [],

			startDate: moment().startOf("day"),
			endDate: moment().endOf("day"),

			keyword: "",

			userTypes: [],

			pendingShow: false,
			isLoading: false,

			confirmOpen: false,
			reversalRow: "",

			modalShow: false,
			modalTitle: "",
			modalBody: "",
			receiptBody: null,

			tooltipOpen: false,
		}
	}
	componentDidMount() {
		console.log(window.innerHeight, window.innerWidth)
		const user = secureStorage.getItem("user")
		const salesType = this.initSalesType()
		this.loadProduct()
		this.loadCategory()
		user.userTypeId === 1 && this.loadPartner()
		this.initUserTypes({ salesType })
		// this.loadTransaction()
	}
	loadPartner() {
		axios
			.post(Config.ApiUrl + "/transaction/partner")
			.then((res) => {
				this.setState({ partners: res.data })
			})
			.catch((error) => {
				console.log(error)
			})
	}
	initSalesType() {
		// ['DM 2 Levels','TL 3 Levels','H2H DM','H2H TL',"DP 3 Levels","H2H RF","FD 5 Levels","FT 5 Levels"]
		const user = secureStorage.getItem("user")
		let salesType = 0
		if ([1].includes(user.userTypeId)) salesType = "All"
		if ([6].includes(user.userTypeId)) salesType = 0
		else if ([2, 3].includes(user.userTypeId)) salesType = 1
		else if ([5].includes(user.userTypeId)) salesType = 2
		else if ([8].includes(user.userTypeId)) salesType = 2
		else if ([9, 10].includes(user.userTypeId)) salesType = 4
		else if ([12].includes(user.userTypeId)) salesType = 5
		else if ([14, 15, 16, 17].includes(user.userTypeId)) salesType = 6
		else if ([19, 20, 21, 22].includes(user.userTypeId)) salesType = 7
		else if ([24, 25].includes(user.userTypeId)) salesType = 8
		else if ([26, 27, 28].includes(user.userTypeId)) salesType = 9

		this.setState({ salesType })
		return salesType
	}

	loadProduct() {
		const selectedCategories = this.state.selectedCategories

		const payload = {
			selectedCategories,
		}

		axios
			.post(Config.ApiUrl + "/transaction/product", payload)
			.then((res) => {
				this.setState({ products: res.data })
			})
			.catch((error) => {
				console.log(error)
			})
	}
	loadCategory() {
		axios
			.post(Config.ApiUrl + "/transaction/category")
			.then((res) => {
				this.setState({ categories: res.data })
			})
			.catch((error) => {
				console.log(error)
			})
	}
	initUserTypes({ salesType }) {
		const user = secureStorage.getItem("user")
		axios
			.post(Config.ApiUrl + "/transaction/usertypes")
			.then((res) => {
				const userTypes = res.data
				this.setState({ userTypes })

				if (salesType !== "All") {
					this.loadUserDropdown({
						salesType,
						parents: [user],
						userTypes,
						parentTypeId: user.userTypeId,
						init: true,
					})
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	loadTransaction = (params) => {
		const user = secureStorage.getItem("user")
		const userTypes = this.state.userTypes
		const users = _.flattenDeep(
			userTypes.filter((item) => item.id !== 1 && this.state[item.id]).map((item) => this.state[item.id])
		)
		this.setState({ isLoading: true })
		const payload = {
			startDate: params && params.startDate ? params.startDate : this.state.startDate,
			endDate: params && params.endDate ? params.endDate : this.state.endDate,
			utcOffset: moment().utcOffset(),

			pendingShow: this.state.pendingShow,

			//users payload
			parents: users.length > 0 ? users : [user],

			keyword: params && params.keyword ? params.keyword : this.state.keyword,

			products: this.state.selectedProducts && this.state.selectedProducts.length > 0 && this.state.selectedProducts,
			categories:
				this.state.selectedCategories && this.state.selectedCategories.length > 0 && this.state.selectedCategories,
			partners: this.state.selectedPartners && this.state.selectedPartners.length > 0 && this.state.selectedPartners,
			salesType: this.state.salesType,
			// pagination payload>>
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
			// <<pagination payload
		}
		// console.log(payload)
		axios
			.post(Config.ApiUrl + "/transaction/log", payload)
			.then((res) => {
				//console.log(res.data.sales)
				this.setState({
					rows: res.data.sortedSales,
					totalPages: res.data.totalPages,
					totalData: res.data.totalData,
					startDate: payload.startDate,
					endDate: payload.endDate,
					isLoading: false,
				})
				// console.log(res.data.sortedSales)
				// this.extractRows(res.data.sortedSales)
				//this.setTotal(res.data.sales)
			})
			.catch((error) => {
				this.setState({ isLoading: false })
				console.log(error)
			})
	}

	loadUserDropdown(params) {
		const user = secureStorage.getItem("user")
		const userTypes = params.userTypes ? params.userTypes : this.state.userTypes
		const salesType = params.salesType ? params.salesType : this.state.salesType
		const payload = {
			salesType,
			parents: params.parents && params.parents,
		}

		console.log({ params, payload })
		axios
			.post(Config.ApiUrl + "/transaction/user", payload)
			.then((res) => {
				// console.log(params)
				let userTypeIdx = params.parentTypeId
				if (params.init && user.userTypeId === 1) userTypeIdx = DirectUserType[salesType] - 1
				// console.log(params.parentTypeId)
				// console.log(userTypes)
				this.setState({ [userTypes[userTypeIdx].name]: res.data })
			})
			.catch((error) => {
				console.log(error)
			})
	}

	getUserDropDown = (userTypeId) => {
		if (this.state.userTypes.length > 0 && this.state[this.state.userTypes[userTypeId - 1].name])
			return (
				<GridItem xl={4} lg={4} md={4} sm={4} xs={6}>
					<Dropdown
						style={{ marginTop: 25 }}
						placeholder={this.state.userTypes[userTypeId - 1].name}
						fluid
						multiple
						search
						selection
						onChange={(e, { value }) => {
							// console.log(value)
							if (value.length === 0 && this.state.userTypes[userTypeId])
								this.setState({
									[this.state.userTypes[userTypeId].name]: undefined,
									[this.state.userTypes[userTypeId].id]: undefined,
								})
							if (!EndUsers.includes(userTypeId) && value.length > 0)
								this.loadUserDropdown({
									// salesType,
									parents: this.state[this.state.userTypes[userTypeId - 1].name].filter(
										(item) => value.indexOf(item.id) !== -1
									),
									// userTypes,
									parentTypeId: userTypeId,
								})
							const val = this.state[this.state.userTypes[userTypeId - 1].name]
								.filter((item) => value.indexOf(item.id) !== -1)
								.map((item) => item)
							// console.log(val)
							this.setState({
								[this.state.userTypes[userTypeId - 1].id]: val,
							})
						}}
						options={this.state[this.state.userTypes[userTypeId - 1].name].map((item, index) => ({
							key: item.id,
							text: item.name,
							value: item.id,
						}))}
					/>
				</GridItem>
			)
	}
	clearUserDropDown = () => {
		this.state.userTypes.map((userType) => {
			this.state[userType.id] && this.setState({ [userType.id]: undefined })
			return userType
		})
	}

	render() {
		const { classes, t } = this.props
		const user = secureStorage.getItem("user")
		return (
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.contactContent}>
					<div className={classes.container}>
						<GridContainer justify="center">
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<GridContainer justify="center">
									<GridItem xl={2} lg={2} md={2} sm={12} xs={12} className={classes.grid}>
										<Label size="huge">{t("transactionLog").toUpperCase()}</Label>
									</GridItem>
									<GridItem xl={10} lg={10} md={10} sm={12} xs={12}>
										<GSearchActions
											onChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
											onSubmit={this.loadTransaction}
										/>
									</GridItem>
								</GridContainer>
								<GridContainer justify="center">
									<GridItem xl={3} lg={3} md={3} sm={12} xs={12}>
										<Dropdown
											style={{ marginTop: 25 }}
											placeholder={t("category")}
											fluid
											multiple
											search
											selection
											value={this.state.selectedCategories}
											onChange={(e, { value }) => {
												this.setState(
													{
														selectedCategories: value,
														selectedProducts: [],
													},
													() => this.loadProduct()
												)
											}}
											options={this.state.categories.map((item, index) => ({
												key: item.id,
												text: item.name,
												value: item.id,
											}))}
										/>
									</GridItem>
									<GridItem xl={3} lg={3} md={3} sm={12} xs={12}>
										<Dropdown
											style={{ marginTop: 25 }}
											placeholder="ITEM"
											fluid
											multiple
											search
											selection
											value={this.state.selectedProducts}
											onChange={(e, { value }) => {
												this.setState({
													selectedProducts: value,
													selectedCategories: [],
												})
											}}
											options={this.state.products.map((item, index) => ({
												key: item.id,
												text: item.name,
												value: item.id,
											}))}
										/>
									</GridItem>
									{user.userTypeId === 1 && (
										<GridItem xl={3} lg={3} md={3} sm={12} xs={12}>
											<Dropdown
												style={{ marginTop: 25 }}
												placeholder="Partner"
												fluid
												multiple
												search
												selection
												value={this.state.selectedPartners}
												onChange={(e, { value }) => {
													this.setState({
														selectedPartners: value,
													})
												}}
												options={this.state.partners.map((item, index) => ({
													key: item.id,
													text: item.name,
													value: item.id,
												}))}
											/>
										</GridItem>
									)}
									{user.userTypeId === 1 && (
										<GridItem xl={3} lg={3} md={3} sm={12} xs={12}>
											<FormControl fullWidth className={classes.selectFormControl}>
												<InputLabel htmlFor="salesType-select" className={classes.selectLabel}>
													Sales Type
												</InputLabel>
												<Select
													MenuProps={{ className: classes.selectMenu }}
													classes={{ select: classes.select }}
													value={this.state.salesType}
													onChange={(e) => {
														const val = e.target.value
														console.log({ val })
														this.setState(
															{
																salesType: val,
															},
															() => {
																if (val === "All") {
																	this.clearUserDropDown()
																} else {
																	this.clearUserDropDown()
																	this.loadUserDropdown({
																		salesType: val,
																		parents: [user],
																		// userTypes,
																		parentTypeId: user.userTypeId,
																		init: true,
																	})
																}
															}
														)
													}}
													inputProps={{ name: "salesType", id: "salesType" }}
												>
													<MenuItem disabled classes={{ root: classes.selectMenuItem }} value={""}>
														Sales Type
													</MenuItem>
													{user && [1].includes(user.userTypeId) && (
														<MenuItem
															classes={{
																root: classes.selectMenuItem,
																selected: classes.selectMenuItemSelected,
															}}
															value="All"
														>
															Semua
														</MenuItem>
													)}
													{SalesType.map((item, index) => (
														<MenuItem
															key={index}
															classes={{
																root: classes.selectMenuItem,
																selected: classes.selectMenuItemSelected,
															}}
															value={index}
														>
															{item}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</GridItem>
									)}
								</GridContainer>
								<GridContainer justify="center">
									{/* const SalesType = ['DM 2 Levels','TL 3 Levels','H2H DM','H2H TL',"DP 3 Levels","H2H RF","FD 5 Levels","FT 5 Levels"] */}
									{user.userTypeId === 1 && this.state.salesType === 0 && this.getUserDropDown(DirectUserType[0])}
									{[1, 6].includes(user.userTypeId) && [0].includes(this.state.salesType) && this.getUserDropDown(7)}

									{user.userTypeId === 1 && this.state.salesType === 1 && this.getUserDropDown(DirectUserType[1])}
									{[1, 2].includes(user.userTypeId) && [1].includes(this.state.salesType) && this.getUserDropDown(3)}
									{[1, 2, 3].includes(user.userTypeId) && [1].includes(this.state.salesType) && this.getUserDropDown(4)}

									{user.userTypeId === 1 && this.state.salesType === 2 && this.getUserDropDown(DirectUserType[2])}
									{user.userTypeId === 1 && this.state.salesType === 3 && this.getUserDropDown(DirectUserType[3])}

									{user.userTypeId === 1 && this.state.salesType === 4 && this.getUserDropDown(DirectUserType[4])}
									{[1, 9].includes(user.userTypeId) && [4].includes(this.state.salesType) && this.getUserDropDown(10)}
									{[1, 9, 10].includes(user.userTypeId) &&
										[4].includes(this.state.salesType) &&
										this.getUserDropDown(11)}

									{user.userTypeId === 1 && this.state.salesType === 5 && this.getUserDropDown(DirectUserType[5])}
									{[1, 12].includes(user.userTypeId) && [5].includes(this.state.salesType) && this.getUserDropDown(13)}

									{user.userTypeId === 1 && this.state.salesType === 6 && this.getUserDropDown(DirectUserType[6])}
									{[1, 14].includes(user.userTypeId) && [6].includes(this.state.salesType) && this.getUserDropDown(15)}
									{[1, 14, 15].includes(user.userTypeId) &&
										[6].includes(this.state.salesType) &&
										this.getUserDropDown(16)}
									{[1, 14, 15, 16].includes(user.userTypeId) &&
										[6].includes(this.state.salesType) &&
										this.getUserDropDown(17)}
									{[1, 14, 15, 16, 17].includes(user.userTypeId) &&
										[6].includes(this.state.salesType) &&
										this.getUserDropDown(18)}

									{user.userTypeId === 1 && this.state.salesType === 7 && this.getUserDropDown(DirectUserType[7])}
									{[1, 19].includes(user.userTypeId) && [7].includes(this.state.salesType) && this.getUserDropDown(20)}
									{[1, 19, 20].includes(user.userTypeId) &&
										[7].includes(this.state.salesType) &&
										this.getUserDropDown(21)}
									{[1, 19, 20, 21].includes(user.userTypeId) &&
										[7].includes(this.state.salesType) &&
										this.getUserDropDown(22)}
									{[1, 19, 20, 21, 22].includes(user.userTypeId) &&
										[7].includes(this.state.salesType) &&
										this.getUserDropDown(23)}

									{user.userTypeId === 1 && this.state.salesType === 8 && this.getUserDropDown(DirectUserType[8])}
									{[1, 24].includes(user.userTypeId) && [8].includes(this.state.salesType) && this.getUserDropDown(25)}

									{user.userTypeId === 1 && this.state.salesType === 9 && this.getUserDropDown(DirectUserType[9])}
									{[1, 26].includes(user.userTypeId) && [9].includes(this.state.salesType) && this.getUserDropDown(27)}
									{[1, 26, 27].includes(user.userTypeId) &&
										[9].includes(this.state.salesType) &&
										this.getUserDropDown(28)}
									{[1, 26, 27, 28].includes(user.userTypeId) &&
										[9].includes(this.state.salesType) &&
										this.getUserDropDown(29)}
								</GridContainer>
							</GridItem>
							<GridItem xl={6} lg={6} md={6} sm={4} xs={4}>
								<Statistic size="tiny" style={{ marginTop: 40 }}>
									<Statistic.Value>{this.state.totalData ? this.state.totalData : 0}</Statistic.Value>
									<Statistic.Label>Record(s)</Statistic.Label>
								</Statistic>
							</GridItem>
							<GridItem xl={4} lg={4} md={4} sm={4} xs={4}>
								<Input
									labelText={t("search")}
									id="keyword"
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										placeholder: "CustomerCode / IDPEL / TRID",
										value: this.state.keyword,
										onChange: (e) => {
											this.setState({ keyword: e.target.value })
										},
										onKeyPress: (e) => {
											if (e.key === "Enter") {
												this.setState({ keyword: e.target.value })
												this.loadTransaction({ keyword: e.target.value })
											}
										},
									}}
								/>
							</GridItem>
							<GridItem xl={2} lg={2} md={2} sm={4} xs={4}>
								<Checkbox
									label="Pending"
									onClick={(e, { checked }) => this.setState({ pendingShow: checked })}
									style={{ marginTop: 40 }}
								/>
							</GridItem>
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<Segment vertical>
									<Dimmer active={this.state.isLoading} inverted>
										<Loader />
									</Dimmer>
									<Table
										// sortable
										stickyHead
										stickyFoot
										striped
										tableHead={this.getHeader()}
										tableData={this.state.rows.map((row, index) => [
											index +
												1 +
												(this.state.currentPage - 1) * (isNaN(this.state.rowsPerPage) ? 0 : this.state.rowsPerPage),
											row.id.toString().padStart(8, 0),
											row.User.id.toString().padStart(6, 0),
											row.User.name,
											row.User.UserType.name,
											row.Product.name,
											row.customerCode,
											moment(row.createdAt).format("DD/MM/YYYY HH:mm:ss"),
											fnumber(row.amount),
											row.RequestType.name === "inquiry" ? (
												<Badge color="warning">{row.RequestType.name}</Badge>
											) : (
												<Badge color="info">{row.RequestType.name}</Badge>
											),
											this.getStatus(row),

											row.receiptBody && row.statusId === 2 ? JSON.parse(row.receiptBody)[0] : "-",

											<Button
												size="mini"
												circular
												color="facebook"
												onClick={() => {
													axios
														.post(Config.ApiUrl + "/transaction/receipt", {
															id: row.id,
														})
														.then((res) => {
															const { receiptTitle, dataJSON, body } = res.data
															const modalTitle = receiptTitle
															const modalBody = modalReceipt(body)
															// console.log({ modalBody });
															this.setState({
																modalShow: true,
																modalTitle,
																modalBody,
																receiptBody: dataJSON,
															})
															console.log(res.data)
														})
														.catch((error) => {
															console.log(error)
														})
												}}
											>
												{t("view")}
											</Button>,

											user.userTypeId === 1 ? (
												row.statusId === 1 && row.RequestType.name === "purchase" ? (
													this.state["loadingAdv" + row.id] ? (
														<CircularProgress size={25} />
													) : (
														<span>
															<Button
																size="mini"
																circular
																color="facebook"
																onClick={() => {
																	// console.log('loadingAdv'+row.id)
																	this.setState({
																		["loadingAdv" + row.id]: true,
																	})
																	// this.handleAdvice(row.id,row.productId)
																	const salesId = row.id
																	const productId = row.productId
																	const payload = {
																		salesId,
																		productId,
																	}
																	axios
																		.post(Config.ApiUrl + "/transaction/advice", payload)
																		.then((res) => {
																			// console.log(this.state['loadingAdv'+salesId])
																			// _.find(this.state.extractedRows,{id:salesId})
																			this.setState({
																				["loadingAdv" + salesId]: false,
																			})
																			this.loadTransaction()
																		})
																		.catch((error) => {
																			// console.log(this.state['loadingAdv'+salesId])
																			this.setState({
																				["loadingAdv" + salesId]: false,
																			})
																			console.log(error)
																		})
																}}
															>
																ADVICE
															</Button>
															<Button
																size="mini"
																circular
																color="youtube"
																onClick={() => {
																	this.setState({
																		confirmOpen: true,
																		reversalRow: row,
																	})
																	// this.handleAdvice(row.id,row.productId)
																}}
															>
																REVERSAL
															</Button>
														</span>
													)
												) : (
													"-"
												)
											) : null,
											user.userTypeId === 1 ? row.Partner.name : null,
										])}
										textAlignForHeaders={[8]}
										textAlignHeaders={["right"]}
										textAlignForCells={[8]}
										textAlignCells={["right"]}
										// rawData={this.state.extractedRows}
										// onSort={(data)=>this.setState({extractedRows:data})}
									/>
								</Segment>
								<Confirm
									open={this.state.confirmOpen}
									onCancel={() => this.setState({ confirmOpen: false })}
									content={
										this.state.reversalRow &&
										`Are you sure doing reversal TRID : ${this.state.reversalRow.id.toString().padStart(8, 0)} ?`
									}
									onConfirm={() => {
										const salesId = this.state.reversalRow.id
										const productId = this.state.reversalRow.productId
										axios
											.post(Config.ApiUrl + "/transaction/reversal", {
												salesId,
												productId,
											})
											.then((res) => {
												// console.log(this.state['loadingAdv'+salesId])
												// _.find(this.state.extractedRows,{id:salesId})
												this.setState({
													["loadingAdv" + salesId]: false,
													confirmOpen: false,
												})
												this.loadTransaction()
											})
											.catch((error) => {
												// console.log(this.state['loadingAdv'+salesId])
												this.setState({
													["loadingAdv" + salesId]: false,
													confirmOpen: false,
												})
												this.loadTransaction()
												console.log(error)
											})
									}}
								/>
							</GridItem>
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12} className={classes.grid}>
								<div className={classes.justifyContentCenter}>
									<Pagination
										totalButton={7}
										totalPages={this.state.totalPages}
										totalData={this.state.totalData}
										currentPage={this.state.currentPage}
										onChangePage={(currentPage) => {
											if (currentPage !== this.state.currentPage) {
												this.setState({ currentPage })
												this.loadTransaction({ currentPage })
											}
										}}
										// pages={[
										// 	...this.paginationButton(),
										// ]}
										rowsPerPage={[5, 15, 25, "All"]}
										// rowsPerPage={user.userTypeId === 1 ? [5, 15, 25, "All"] : [5, 15, 25]}
										defaultRowsPerPage={15}
										onChangeRowsPerPage={(value) => {
											this.setState({ rowsPerPage: value, currentPage: 1 })
											this.loadTransaction({
												rowsPerPage: value,
												currentPage: 1,
											})
										}}
									/>
								</div>
							</GridItem>
						</GridContainer>
						{/* Modal >> */}
						<Dialog
							classes={{
								root: classes.modalRoot,
								paper: classes.modal,
							}}
							open={this.state.modalShow}
							TransitionComponent={CustomTransition}
							keepMounted
							//onClose={() => this.setState({successModal: false})}
							aria-labelledby="classic-modal-slide-title"
							aria-describedby="classic-modal-slide-description"
						>
							<DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
								<h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
							</DialogTitle>
							<DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
								{/* {this.state.modalBody} */}
								<Tab
									panes={[
										{
											menuItem: "Struk",
											render: () => <Tab.Pane>{this.state.modalBody}</Tab.Pane>,
										},
										{
											menuItem: "JSON",
											render: () => (
												<Tab.Pane>
													<div style={{ textAlign: "right" }}>
														<ClickAwayListener onClickAway={(e) => this.setState({ tooltipOpen: false })}>
															<Tooltip
																PopperProps={{
																	disablePortal: true,
																}}
																onClose={(e) => this.setState({ tooltipOpen: false })}
																open={this.state.tooltipOpen}
																disableFocusListener
																disableHoverListener
																disableTouchListener
																placement="top-end"
																title="Copied"
															>
																<Button
																	size="mini"
																	color="twitter"
																	onClick={() => {
																		this.setState({ tooltipOpen: true })
																		console.log(this.state.receiptBody)
																		navigator.clipboard.writeText(JSON.stringify(this.state.receiptBody, null, 2))
																	}}
																>
																	<FileCopy />
																</Button>
															</Tooltip>
														</ClickAwayListener>
													</div>
													<ReactJson
														displayObjectSize={false}
														src={this.state.receiptBody}
														name={false}
														enableClipboard={false}
														displayDataTypes={false}
													/>
												</Tab.Pane>
											),
										},
									]}
								/>
							</DialogContent>
							<DialogActions className={classes.modalFooter}>
								<CButton onClick={() => this.setState({ modalShow: false })} color="facebook" round>
									<Close />
									{t("close")}
								</CButton>
							</DialogActions>
						</Dialog>
						{/* << Modal */}
					</div>
				</div>
			</div>
		)
	}
	getHeader() {
		const { t } = this.props
		const user = secureStorage.getItem("user")
		const header = [
			"#",
			"TRID",
			"UID",
			t("name"),
			t("userType"),
			"Item",
			"CustomerCode",
			t("date"),
			"Amount",
			"TrType",
			"Status",
			t("remark"),
			"Detail",
		]
		if (user.userTypeId === 1) {
			header.push("Advice")
			header.push("Switching")
		}
		return header
	}

	getStatus(row) {
		let statusColor = "info"
		switch (row.statusId) {
			case 4:
				statusColor = "success"
				break
			case 2:
				statusColor = "danger"
				break
			case 1:
				statusColor = "warning"
				break
			default:
		}
		return <Badge color={statusColor}>{row.TransactionStatus.name}</Badge>
	}

	handleAdvice = (salesId, productId) => {
		const payload = {
			salesId,
			productId,
		}
		axios
			.post(Config.ApiUrl + "/transaction/advice", payload)
			.then((res) => {
				// console.log(this.state['loadingAdv'+salesId])
				// _.find(this.state.extractedRows,{id:salesId})
				this.setState({ ["loadingAdv" + salesId]: false })
				this.loadTransaction()
			})
			.catch((error) => {
				// console.log(this.state['loadingAdv'+salesId])
				this.setState({ ["loadingAdv" + salesId]: false })
				console.log(error)
			})
	}
}
TransactionLog.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default compose(withTranslation(), withStyles(styles))(TransactionLog)
